<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :makeFocus="false">
      <template v-slot:title> Выбор документа для оплаты </template>
      <v-card-text style="height: 70vh; overflow: auto" class="pa-0">
        <a-table-f-api
          ref="table"
          :api="url"
          :model="model"
          :useQuery="true"
          :defaults="{
            sort: { key: 'id', order: 'DESC' },
            filters: filter,
            paramName: 'documentOrder',
          }"
          @click="clickRow"
        >
        </a-table-f-api>
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, genModel],
  props: {
    value: Boolean,
    object_id: Number,
    vendor_id: { type: Number, default: undefined },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      filter: { status: 2, vendor_id: this.vendor_id },
      filter2: { status: 2, type: [1, 2] },
    };
  },
  computed: {
    model() {
      console.log("get model doc", this.config);
      let fields = this.config.document.docFields.split(",") || [];
      let model = JSON.parse(JSON.stringify(this.$store.getters["config/get"].models[this.config.document.model]));
      model = this.getModelList(model);
      model = model.filter((el) => {
        return fields.includes(el.name);
      });

      return model;
    },
    url() {
      return this.config.document.api;
    },
  },
  created() {},
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    clickRow(d) {
      this.$emit("select", d.row);
      this.$emit("input");
    },
    add() {
      let rows = [];
      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push({
          good_id: row.id,
          name: row.name,
          amount: row.amount,
          price_plan: row.price,
          price_real: row.price,
        });
      }
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>
